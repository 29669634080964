// @file Network requests related to wall from surface

import { asciiSafeStringify } from '@@/bits/json_stringify'
import type { MagicTemplateFormData, MagicTemplateKey } from '@@/bits/magic_padlet_helper'
import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson, fetchJsonWithRetries } from '@@/surface/api_fetch'
import type {
  Comment,
  HashId,
  LibraryId,
  MagicWallCreationResult,
  Wall as WallType,
  WallCreationFromTemplateResult,
  WallId,
  WallNameAvailability,
} from '@@/types'
import type { JsonAPIResponse, Wish } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

const API_VERSION = 9
const VIA = 'webapp'

class WallApi extends FetchableObject {
  private static readonly API_VERSION = 9

  public static get url(): string {
    return `/api/${API_VERSION}/walls`
  }

  private static get convertLayoutUrl(): string {
    return `api/${API_VERSION}/walls/\${wallHashid}/layout`
  }

  private static get summaryUrl(): string {
    return `api/${API_VERSION}/walls/\${ hashid }/summary`
  }

  public static get approveAllContributionsUrl(): string {
    return `/api/1/walls/\${ wallHashid }/approve-all-contributions`
  }

  public static async approveAll(
    { wallHashid, approvalCutoffTimestamp = null }: { wallHashid: HashId; approvalCutoffTimestamp: string | null },
    fetchOptions = {},
  ): Promise<{ data: { id: Id; attributes: { approved_wishes: Wish[]; approved_comments: Comment[] } } }> {
    return await fetchJson(this.buildUrl(this.approveAllContributionsUrl, { wallHashid }), {
      method: HTTPMethod.post,
      body: asciiSafeStringify({
        approval_cutoff_timestamp: approvalCutoffTimestamp,
      }),
      ...fetchOptions,
    })
  }

  public static async fetch(hashid: HashId, fetchOptions: VersionedFetchOptions = {}): Promise<WallType> {
    const { apiVersion = 7, ...otherFetchOptions } = fetchOptions
    const response = await fetchJson(`/api/${apiVersion}/walls/${hashid}`, {
      method: HTTPMethod.get,
      ...otherFetchOptions,
    })
    const wall = {
      ...response.data.attributes,
      links: response.data.links,
    }
    return wall
  }

  public static async update(obj: Partial<WallType>, fetchOptions = {}): Promise<any> {
    return await fetchJsonWithRetries(`${this.url}/${obj.hashid ?? ''}`, {
      method: HTTPMethod.put,
      body: asciiSafeStringify(obj),
      ...fetchOptions,
    })
  }

  public static async convertLayout({ wallHashid, newLayout }, fetchOptions = {}): Promise<any> {
    return await fetchJson(this.buildUrl(this.convertLayoutUrl, { wallHashid }), {
      method: HTTPMethod.put,
      body: asciiSafeStringify({ type: newLayout }),
      ...fetchOptions,
    })
  }

  public static async fetchSummary({ hashid }, fetchOptions = {}): Promise<any> {
    return await fetchJson(this.buildUrl(this.summaryUrl, { hashid, apiVersion: API_VERSION }), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async create(
    {
      viz,
      libraryId,
      groupBySection,
      createdFrom,
      sourceId,
      title,
      description,
    }: {
      viz: string
      libraryId?: LibraryId
      groupBySection?: boolean
      createdFrom?: string
      sourceId?: string
      title?: string
      description?: string
    },
    fetchOptions = {},
  ): Promise<any> {
    const wishArrangement = groupBySection === true ? { wish_arrangement: { group_by: 'section' } } : {}
    return await fetchJson(`/api/${API_VERSION}/walls`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({
        viz,
        via: VIA,
        library_id: libraryId,
        ...wishArrangement,
        created_from: createdFrom,
        source_id: sourceId,
        title,
        description,
      }),
      ...fetchOptions,
    })
  }

  public static async createMagic(
    {
      currentLibraryId,
      isExample,
      includeImages,
      magicTemplateKey,
      formData,
    }: {
      currentLibraryId: LibraryId | undefined
      isExample: boolean
      includeImages: boolean
      magicTemplateKey: MagicTemplateKey
      formData: MagicTemplateFormData[typeof magicTemplateKey]
    },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<MagicWallCreationResult>> {
    return await fetchJson(`/api/${API_VERSION}/walls/magic_wall`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({
        library_id: currentLibraryId,
        is_example: isExample,
        include_images: includeImages,
        wall_type: magicTemplateKey,
        ...formData,
      }),
      ...fetchOptions,
    })
  }

  public static async logMagicWallFeedback(feedback: string, wallId: WallId, fetchOptions = {}): Promise<void> {
    await fetchJson(`/api/${API_VERSION}/walls/magic-wall/feedback`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ data: { attributes: { feedback, wallId } } }),
      ...fetchOptions,
    })
  }

  public static async configureExitTicket(
    wallId: WallId,
    userPrompt: string,
    activityDescription: string,
    userLocale: string,
    fetchOptions = {},
  ): Promise<JsonAPIResponse<MagicWallCreationResult>> {
    return await fetchJson(`/api/${API_VERSION}/walls/magic_wall`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({
        wall_type: 'exit_ticket',
        wall_id: wallId,
        user_prompt: userPrompt,
        activity_description: activityDescription,
        user_locale: userLocale,
      }),
      ...fetchOptions,
    })
  }

  public static async generateSamplePost(
    wallHashid: HashId,
    countryCode: string,
    userTimezone: string,
  ): Promise<JsonAPIResponse<any>> {
    return await fetchJson(`/api/${API_VERSION}/magic-wall/sample-post`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({
        wall_hashid: wallHashid,
        country_code: countryCode,
        user_timezone: userTimezone,
      }),
      headers: {
        'X-UID': '', // remove x-uid header so current_user can listen to 'add_wish' realtime event for the post to show up.
      },
    })
  }

  public static async archive(wallId: number): Promise<JsonAPIResponse<WallType>> {
    return await fetchJson(`/api/${API_VERSION}/walls/${wallId}/archive`, {
      method: HTTPMethod.put,
    })
  }

  public static async markAsTemplate(
    wallId: number,
    template: boolean,
  ): Promise<JsonAPIResponse<{ template: boolean }>> {
    return await fetchJson(`/api/${API_VERSION}/walls/${wallId}/mark-as-template`, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ data: { attributes: { template } } }),
    })
  }

  public static async createFromTemplate(
    wallId: number,
    fetchOptions = {},
  ): Promise<JsonAPIResponse<WallCreationFromTemplateResult>> {
    return await fetchJson(`/api/${API_VERSION}/walls/${wallId}/create-from-template`, {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async freeze(wallId: number, fetchOptions: VersionedFetchOptions = {}): Promise<void> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    return await fetchJson(`/api/${apiVersion}/walls/${wallId}/freeze`, {
      method: HTTPMethod.put,
      ...otherFetchOptions,
    })
  }

  public static async unfreeze(wallId: number, fetchOptions: VersionedFetchOptions = {}): Promise<void> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    return await fetchJson(`/api/${apiVersion}/walls/${wallId}/unfreeze`, {
      method: HTTPMethod.put,
      ...otherFetchOptions,
    })
  }

  public static async trash(wallId: WallId): Promise<JsonAPIResponse<WallType>> {
    return await fetchJson(`/api/${API_VERSION}/walls/${wallId}/trash`, {
      method: HTTPMethod.patch,
    })
  }

  public static async transfer(params: { wallId: WallId; destinationLibraryId: LibraryId | null }): Promise<void> {
    return await fetchJson('api/1/walls/transfer', {
      method: HTTPMethod.post,
      jsonData: params,
    })
  }

  public static async checkAvailability(name: string, wallHashid?: HashId): Promise<WallNameAvailability> {
    return await fetchJson(`/api/9/walls/check-availability`, {
      method: HTTPMethod.get,
      query: wallHashid !== undefined ? { name, for_wall_hashid: wallHashid } : { name },
    })
  }
}

export { WallApi }
